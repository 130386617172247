///
/// Miscellaneous and shared store functions
///

import * as types from '../types'
import { router } from '@/lib/router'

/// STATE
const state = () => ({
	loading: 0,
	error: null,
})

const actions = {
	setLoading({ commit }, status) {
		if (status) {
			commit(types.SET_LOADING, 1)
		} else {
			commit(types.SET_LOADING, -1)
		}
	},
	setError({ commit }, error) {
		try {
			error = JSON.parse(error.message || error)
		} catch (error) {}


		if (error && error.code && error.code == 400) {
			return router.push({name: 'login'})
		}
		commit(types.SET_ERROR, error.message || error)
	},
}

const mutations = {
	[types.SET_LOADING](state, status) {
		state.loading = Math.max(0, (state.loading + status) )
	},
	[types.SET_ERROR](state, msg) {
		state.error = msg
	},
}

const getters = {
	loading: state => !!state.loading,
	error: state => state.error,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}

///
/// Vuex Module - Tools
///

import Vue from 'vue'
import { API } from '@/lib/api'
import * as types from '../types'

/// STATE
const state = () => ({
	salesforceResults: [],
})

/// ACTIONS
const actions = {
	async salesforceUsers() {
		return await API.send(`/users/_syncforce`)
	},
	async salesforceOrgs() {
		return await API.send(`/organizations/_syncforce`)
	},
}

/// MUTATIONS
const mutations = {

}

/// GETTERS
const getters = {
	salesforceResults: state => state.salesforceResults,
}

/// EXPORT
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}

///
/// Vuex Module - Registration
///

import Vue from 'vue'
import { API } from '@/lib/api'
import * as types from '../types'
import { RegistrationStatus } from '@/lib/enums'

/// STATE
const state = () => ({
	registration: {},
	registrations: [],
})

/// ACTIONS
const actions = {
	async create({ commit, dispatch }, registration) {
		let registered
		try {
			registered = await API.send(`/registrations`, registration, false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return registered
	},
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		let registrations
		try {
			registrations = await API.get(`/registrations`, params)
			commit(types.SET_REGISTRATIONS, registrations)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return registrations
	},
	async get({ commit }, regId) {
		let registration
		try {
			registration = await API.get(`/registrations/${regId}`)
			commit(types.SET_REGISTRATION, registration)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return registration
	},
	async remove({ commit }, regId) {
		try {
			await API.remove(`/registrations/${regId}`)
			commit(types.SET_REGISTRATION, null)
			commit(types.MOVE_REGISTRATION, regId)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}
	},
	async move({ commit }, {regId, data}) {
		let user
		try {
			user = await API.send(`/registrations/move/${regId}`, data)
			commit(types.MOVE_REGISTRATION, regId)
			return user
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}
	},
	async unset({ commit }) {
		commit(types.UNSET_REGISTRATION)
	}
}
/// MUTATIONS
const mutations = {
	[types.SET_REGISTRATIONS](state, registrations) {
		state.registrations = registrations
	},
	[types.SET_REGISTRATION](state, registration) {
		state.registration = registration
	},
	[types.UNSET_REGISTRATION](state) {
		state.registration = {}
	},
	[types.MOVE_REGISTRATION](state, regId) {
		const idx = state.registrations.findIndex(r => r.regId == regId)
		if (idx > -1) {
			state.registrations[idx].status = RegistrationStatus.removed
		}
		Vue.set(state, 'registrations', state.registrations)
	},
}

/// GETTERS
const getters = {
	registrations: state => state.registrations,
	registration: state => state.registration,
}

/// EXPORT
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}

/**
 * Vue Filter - Date
 */

// Dependencies
import format from 'date-fns/format'

/**
 * Change date format.
 * @param {number} val Date value
 * @param {string} f Date format
 */
export default function date(val = 0, f = 'MM/dd/yyyy') {
	if (!val) return ''
	if (typeof val.toDate !== 'undefined')
		val = val.toDate()
	return format(new Date(val), f)
}

import { parseDomain, ParseResultType } from 'parse-domain'

export const getMainDomain = () => {
	let thisDomain = window.location.hostname

	try {
		const parseResult = parseDomain(window.location.hostname)
		if (parseResult.type == ParseResultType.Listed) {
			const { subDomains, domain, topLevelDomains } = parseResult
			thisDomain = [domain, topLevelDomains].join('.')
		}
	} catch (error) {
		console.warn('catch', error)
	}

	return thisDomain

}

export function forceDownload(data, filename = `download.csv`) {
	const url = window.URL.createObjectURL(new Blob([data]))
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', filename)
	document.body.appendChild(link)
	link.click()
	link.parentNode.removeChild(link)
}

export const flatten = function (data, sep = '/') {
	var result = {};
	function recurse(cur, prop) {
		if (Object(cur) !== cur) {
			result[prop] = cur;
		} else if (Array.isArray(cur)) {
			for (var i = 0, l = cur.length; i < l; i++)
				recurse(cur[i], prop ? prop + sep + i : "" + i);
			if (l == 0)
				result[prop] = [];
		} else {
			var isEmpty = true;
			for (var p in cur) {
				isEmpty = false;
				recurse(cur[p], prop ? prop + sep + p : p);
			}
			if (isEmpty)
				result[prop] = {};
		}
	}
	recurse(data, "");
	return result;
}

export const cleanObject = function(obj) {
	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}

///
/// Vuex Module - Navigation
///

import * as types from '../types'
import { auth } from '@/lib/auth'

/// STATE
const state = () => ({
	menuOpen: false,
	menus: {
		user: [
			{
				title: 'Resources',
				icon: 'apps',
				link: {
					name: 'main',
				},
			},
		],
		dashboard: [
			{
				title: 'My Profile',
				icon: 'person',
				link: {
					name: 'profile',
				},
			},
			{
				title: 'Help & Support',
				icon: 'help-buoy',
				link: {
					name: 'help',
				},
			},
			// {
			// 	title: 'Billing & Invoices',
			// 	icon: 'card-outline',
			// 	link: {
			// 		name: 'invoices',
			// 	},
			// 	admin: true,
			// },
		],
		admin: [
			{
				title: 'Manage Registrations',
				icon: 'file-tray-full',
				link: {
					name: 'registrations',
				},
				admin: true,
			},
			{
				title: 'Manage Users',
				icon: 'people-circle-outline',
				link: {
					name: 'users',
				},
				admin: true,
			},
			{
				title: 'Manage Organizations',
				icon: 'business',
				link: {
					name: 'organizations',
				},
				admin: true,
			},
		],
		bval_admin: [
			{
				title: 'Manage Users',
				icon: 'people-circle-outline',
				link: {
					name: 'users',
				},
				admin: true,
			},
		],
		super: [
			{
				title: 'Manage Resources',
				icon: 'apps',
				link: {
					name: 'applications',
				},
				admin: true,
			},
			{
				title: 'Tools',
				icon: 'construct',
				link: {
					name: 'tools',
				},
				super: true,
			},
		]
	},
})

/// ACTIONS
const actions = {
	menuToggle(context, status) {
		if (!status) {
			status = !context.state.menuOpen
		}
		context.commit(types.MENU_TOGGLE, status)
	}
}

/// MUTATIONS
const mutations = {
	[types.MENU_TOGGLE](state, status) {
		state.menuOpen = status
	},
}

/// GETTERS
const getters = {
	menuOpen: state => state.menuOpen,
	menu: state => key => {
		if (key == 'admin') {
			const user = auth.getUser()
			if (!user?.permissions?.admin) return []
		}
		if (key == 'super') {
			const user = auth.getUser()
			if (!user?.permissions?.super) return []
		}
		if (key == 'bval_admin') {
			const user = auth.getUser()
			if (!user?.permissions?.bval_admin) return []
		}
		return state.menus[key]
	}
}

/// EXPORT
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}


const TOKEN_KEY = 'cs.auth'
import Cookies from 'js-cookie'
import { getMainDomain } from '@/lib/utils'

export class Auth {
	static instance
	static user

	constructor() {
	}

	static getInstance() {
		if (!Auth.instance) {
			Auth.instance = new Auth()
		}

		return Auth.instance
	}

	login(credentials) {
		const { expires_in } = credentials
		// const _toStore = JSON.stringify({
		// 	value: credentials,
		// 	expires: (new Date()).getTime() + (expires_in * 1000),
		// })

		const _toStore = JSON.stringify(credentials)
		const domain = getMainDomain()
		Cookies.set(TOKEN_KEY, _toStore, {
			domain,
			secure: true,
			expires: (expires_in / 3600 / 24),
		})
	}

	logout() {
		// return localStorage.removeItem(TOKEN_KEY)
		const domain = getMainDomain()
		return Cookies.remove(TOKEN_KEY, {
			domain,
		})
	}

	getUser() {
		let user = Cookies.get(TOKEN_KEY)
		if (!user) return
		try {
			user = JSON.parse(user)
		} catch (error) {
			console.error(error)
		}
		return user
	}

	getToken() {
		const user = this.getUser()
		if (user) {
			return user.token
		}
		return undefined
	}
}

export const auth = Auth.getInstance()

///
/// Vuex Module - Application
///

import Vue from 'vue'
import { API } from '@/lib/api'
import * as types from '../types'

/// STATE
const state = () => ({
	application: {},
	applications: [],
})

/// ACTIONS
const actions = {
	async create({ commit, dispatch }, data) {
		let application
		try {
			application = await API.send(`/applications`, data)
			commit(types.SET_APPLICATION, application)
		} catch (error) {
			this.dispatch('misc/setError', error)
			throw new Error(error.message || error)
		}

		return application
	},
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		let applications
		try {
			applications = await API.get(`/applications`, params)
			commit(types.SET_APPLICATIONS, applications)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return applications
	},
	async get({ commit }, appId) {
		let application
		try {
			application = await API.get(`/applications/${appId}`)
			commit(types.SET_APPLICATION, application)
		} catch (error) {
			this.dispatch('misc/setError', error)
			throw new Error(error.message || error)
		}

		return application
	},
	async update({ commit }, { appId, data }) {
		let application
		try {
			application = await API.update(`/applications/${appId}`, data)
			commit(types.SET_APPLICATION, application)
		} catch (error) {
			this.dispatch('misc/setError', error)
			throw new Error(error.message || error)
		}
	},
	async unset({ commit }) {
		commit(types.UNSET_APPLICATION)
	},
	async generateSecret({ commit }, appId) {
		let appSecret
		try {
			appSecret = await API.send(`/applications/${appId}/secret`)
		} catch (error) {
			throw new Error(error.message || error)
		}

		return appSecret
	},
	async requestInfo({ commit }, appId) {
		this.dispatch('misc/setLoading', true)

		let request
		try {
			request = await API.send(`/applications/${appId}/request`)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return request
	},
	async requestSupport({ commit }, { appId, message }) {
		this.dispatch('misc/setLoading', true)

		let request
		try {
			request = await API.send(`/applications/${appId}/support`, { message })
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return request
	},
}
/// MUTATIONS
const mutations = {
	[types.SET_APPLICATIONS](state, applications) {
		state.applications = [
			...applications,
		]
	},
	[types.SET_APPLICATION](state, application) {
		state.application = { ...application }

		const idx = state.applications.findIndex(a => a.appId == application.appId)
		if (idx > -1) {
			state.applications[idx] = application
		} else {
			state.applications.push(application)
		}
		Vue.set(state, 'applications', [...state.applications] )
	},
	[types.UNSET_APPLICATION](state) {
		state.application = {}
	},
}

/// GETTERS
const getters = {
	applications: state => state.applications,
	application: state => state.application,
}

/// EXPORT
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}

///
/// Vuex Module - Banner
///

import Vue from 'vue'
import { API } from '@/lib/api'
import * as types from '../types'
import { flatten } from '@/lib/utils'

/// STATE
const state = () => ({
	banner: {},
	banners: [],
})

/// ACTIONS
const actions = {
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		let banners
		try {
			banners = await API.get(`/banners`, params)
			commit(types.SET_BANNERS, banners)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return banners
	},
	async get({ commit }, bannerId) {
		let banner
		try {
			banner = await API.get(`/banners/${bannerId}`)
			commit(types.SET_BANNER, banner)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return banner
	},
	async update({ commit }, { bannerId, data }) {
		this.dispatch('misc/setLoading', true)

		let banner
		try {
			banner = await API.update(`/banners/${bannerId}`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_BANNER, banner)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return banner
	},
	async remove({ commit }, bannerId) {
		try {
			await API.remove(`/banners/${bannerId}`)
			commit(types.UNSET_BANNER)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}
	},
	async create({ commit }, data) {
		this.dispatch('misc/setLoading', true)

		let banner
		try {
			banner = await API.send(`/banners`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_BANNER, banner)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return banner
	},
	async uploadImage({ commit }, {bannerId, data }) {
		this.dispatch('misc/setLoading', true)

		try {
			const banner = await API.send(`/banners/image/${bannerId}`, data, true, {
				'content-type': 'application/x-www-form-urlencoded'
			})
			this.dispatch('misc/setLoading', false)
			return commit(types.SET_BANNER, banner)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}
	},
	async unset({ commit}) {
		commit(types.UNSET_BANNER)
	},
}

/// MUTATIONS
const mutations = {
	[types.SET_BANNERS](state, banners) {
		state.banners = banners
	},
	[types.SET_BANNER](state, banner) {
		state.banner = banner

		const idx = state.banners.findIndex(o => o.bannerId == banner.bannerId)
		if (idx > -1) {
			state.banners[idx] = banner
		} else {
			state.banners.push(banner)
		}
		Vue.set(state, 'banners', [...state.banners])
	},
	[types.UNSET_BANNER](state) {
		state.banner = {}
	},
}

/// GETTERS
const getters = {
	banners: state => state.banners,
	banner: state => state.banner,
}

/// EXPORT
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
